<template>
    <v-dialog
        v-model="dialog"
        max-width="700"
    >
        
        <v-toolbar
            color="primary"
            dark
            flat
        >
            <v-toolbar-title>
                Nuevo procedimiento
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="cancela">
                <v-icon>mdi-close-box</v-icon>
            </v-btn>
        </v-toolbar>

        <v-form v-model="formValid" ref="form">
            <v-card>
                <v-card-text>
                    <v-autocomplete
                        v-model="procedimientosList"
                        class="px-1"
                        :items="items"
                        :loading="loadingProcs"
                        label="Procedimientos"
                        :search-input.sync="query"
                        item-text="procedimiento"
                        :rules="[value => !!value || 'Requerido.']"
                        item-value="id"
                        no-filter
                        rounded
                        filled
                        return-object
                        append-outer-icon="mdi-magnify"
                        @click:append-outer="dialogCatalogo=true"
                    />
                    <div class="d-flex flex-row">
                        <v-text-field
                            label="Duración (minutos)"
                            rounded
                            filled
                            dense
                            type="number"
                            v-model.number="proc.tiempo"
                            :rules="[value => !!value || 'Requerido.']"
                            
                        />
                        <v-select
                            label="Turno"
                            v-model="proc.turno"
                            :items="itemTurnos"
                            :rules="[value => !!value || 'Requerido.']"
                            rounded
                            filled
                        />

                    </div>
                    <v-textarea
                        label="Observaciones"
                        rounded
                        filled
                        dense
                        type="number"
                        v-model="proc.observaciones"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="cancela">Cancelar</v-btn>
                    <v-btn color="success" :disabled="!formValid" @click="guarda" :loading="loadingGuarda">Guardar</v-btn>
                    
                </v-card-actions>
            </v-card>
        </v-form>
        <catalogo-proc
            :dialogCatalogo.sync="dialogCatalogo"
            :procedimientos.sync="items"
            :procedimientosList.sync="procedimientosList"
        />

    </v-dialog>
</template>

<script>
export default {
    components:{
        'catalogo-proc': () => import('@/components/hospital/enfe/procedimientos/CatalogoProcedimientos'),
    },
    props:{
        dialogNuevo:Boolean,
        inter:Number,
        estaFecha:String,
    },

    data:()=>({
        
        itemTurnos:[
            {text:'Matutino', value:'mat'},
            {text:'Vespertino', value:'ves'},
            {text:'Nocturno', value:'noc'},
        ],
        loadingGuarda:false,
        formValid:false,
        datosProc:{},

        query:'',
        items:[],
        loadingProcs:false,
        total:0,
        max:10,
        page:1,

        proc:{},

        procedimientosList:{},

        dialogCatalogo:false
    }),

    methods:{
        async guarda(){
            this.loadingGuarda=true
            this.proc.internamiento = this.inter
            this.proc.fecha=this.estaFecha
            this.proc.hora='-'
            try{
                let req = await this.$http({
                    url:'/enfermeria/guardaProcedimientos2',
                    method:'POST',
                    params:this.proc,
                })
                this.loadingGuarda=false
                if (req.data.estado){
                    this.$emit('guardado')
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Registro agregado exitosamente',
                    })
                    this.dialog = false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingGuarda=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cancela(){
            this.dialog=false
        },

        async cargaProcs(){
            //this.itemsUnidad=[]
            if (!this.loadingProcs && this.query.length>=3){
                this.loadingProcs = true
                
                try{
                    let consulta = await this.$http({
                        url:'/catalogos/catProcEnf',
                        method:'GET',
                        params:{
                            //page:page,
                            query:this.query,
                            //start:start,
                            //limit:this.max,
                        },
                    })
                    this.items=consulta.data
                    this.loadingProcs = false
                }catch(err){
                    this.loadingProcs = false
                    this.$store.dispatch('security/revisaError',err)
                }
            }
        },
    },

    computed:{
        dialog:{
            get(){
                return this.dialogNuevo
            },
            set(val){
                this.$emit('update:dialogNuevo',val)
            },
        },
        
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.form.reset()
            }
        },
        query(v){
            if (v && v.length>=3 && !this.loadingProcs){
                this.cargaProcs()
            }
            if (!v || v.length<3){
                this.items = []
            }
            
        },
        'procedimientosList.id'(v){
            if (v){
                this.proc.procedimiento = this.procedimientosList.id
                this.proc.tiempo = this.procedimientosList.tiempo
            }
        }
    },
}
</script>

<style>

</style>